<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'AZURE_ACCOUNTS' | translate }}">{{ "AZURE_ACCOUNTS" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li ngbNavItem id="azure-accounts-list-tab">
                <a ngbNavLink class="nav-link" title="{{ 'ACCOUNTS' | translate }}">{{ "ACCOUNTS" | translate }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem id="azure-accounts-doc-tab">
                <a ngbNavLink class="nav-link" title="{{ 'DOCUMENTATION' | translate }}">{{ "DOCUMENTATION" | translate }}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col mt-3">
                                <h3 translate>AZURE_DOCS.TITLE</h3>
                                <ol>
                                    <li>
                                        {{ "AZURE_DOCS.ONE_A" | translate }}
                                        <a href="https://portal.azure.com/#blade/Microsoft_Azure_Billing/SubscriptionsBlade" target="_blank"
                                            >https://portal.azure.com/#blade/Microsoft_Azure_Billing/SubscriptionsBlade</a
                                        ><br />
                                        {{ "AZURE_DOCS.ONE_B" | translate }}
                                    </li>
                                    <li>
                                        <div class="mb-3">{{ "AZURE_DOCS.TWO" | translate }}</div>
                                        <div class="form-group">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="{{ 'SUBSCRIPTION_ID' | translate }}"
                                                [(ngModel)]="azureSubscriptionId"
                                            />
                                        </div>
                                        <fieldset class="form-group" *ngIf="!isEdit">
                                            <mat-button-toggle-group name="newAccount" aria-label="new account" [(ngModel)]="newAccount">
                                                <mat-button-toggle [value]="true">{{ "NEW" | translate }} {{ "ACCOUNT" | translate }}</mat-button-toggle>
                                                <mat-button-toggle [value]="false">{{ "UPDATE" | translate }} {{ "EXISTING" | translate }}</mat-button-toggle>
                                            </mat-button-toggle-group>
                                        </fieldset>
                                        <div>
                                            <button
                                                *ngIf="newAccount"
                                                class="btn btn-sm btn-link"
                                                (click)="copyNewAccount()"
                                                type="button"
                                                title="{{ 'COPY' | translate }}"
                                            >
                                                <fa-icon icon="clipboard" size="md"></fa-icon>
                                            </button>
                                            <button
                                                *ngIf="!newAccount"
                                                class="btn btn-sm btn-link"
                                                (click)="copyNotNewAccount()"
                                                type="button"
                                                title="{{ 'COPY' | translate }}"
                                            >
                                                <fa-icon icon="clipboard" size="md"></fa-icon>
                                            </button>
                                        </div>
                                        <pre *ngIf="newAccount">
Get-AzureRmSubscription -SubscriptionId "{{ azureSubscriptionId }}" | Set-AzureRmContext
Register-AzResourceProvider -ProviderNamespace Microsoft.Compute
Register-AzResourceProvider -ProviderNamespace Microsoft.Authorization
Register-AzResourceProvider -ProviderNamespace Microsoft.Network
Register-AzResourceProvider -ProviderNamespace Microsoft.ResourceHealth
Register-AzResourceProvider -ProviderNamespace Microsoft.Resources
Register-AzResourceProvider -ProviderNamespace Microsoft.Storage
$role = [Microsoft.Azure.Commands.Resources.Models.Authorization.PSRoleDefinition]::new()
$role.Name = 'Zen Master {{ azureSubscriptionId }}'
$role.Description = "Lets Zen Master manage virtual machines, but not access to them, and not the virtual network or storage account they're connected to."
$role.IsCustom = $true
$perms = 'Microsoft.Compute/availabilitySets/*','Microsoft.Compute/locations/*','Microsoft.Compute/virtualMachines/*'
$perms += 'Microsoft.Compute/disks/delete'
$perms += 'Microsoft.Compute/galleries/images/read'
$perms += 'Microsoft.Compute/galleries/images/versions/read'
$perms += 'Microsoft.Authorization/*/read'
$perms += 'Microsoft.Network/applicationGateways/backendAddressPools/join/action'
$perms += 'Microsoft.Network/loadBalancers/backendAddressPools/join/action'
$perms += 'Microsoft.Network/loadBalancers/inboundNatPools/join/action'
$perms += 'Microsoft.Network/loadBalancers/inboundNatRules/join/action'
$perms += 'Microsoft.Network/loadBalancers/probes/join/action'
$perms += 'Microsoft.Network/loadBalancers/read'
$perms += 'Microsoft.Network/locations/*'
$perms += 'Microsoft.Network/networkInterfaces/*'
$perms += 'Microsoft.Network/networkSecurityGroups/join/action'
$perms += 'Microsoft.Network/networkSecurityGroups/read'
$perms += 'Microsoft.Network/publicIPAddresses/*'
$perms += 'Microsoft.Network/virtualNetworks/read'
$perms += 'Microsoft.Network/virtualNetworks/subnets/join/action'
$perms += 'Microsoft.ResourceHealth/availabilityStatuses/read'
$perms += 'Microsoft.Resources/deployments/*'
$perms += 'Microsoft.Resources/subscriptions/resourceGroups/*'
$perms += 'Microsoft.Storage/storageAccounts/listKeys/action'
$perms += 'Microsoft.Storage/storageAccounts/read'
$perms += 'Microsoft.Network/publicIPPrefixes/read'
$perms += 'Microsoft.Network/publicIPPrefixes/join/action'
$role.Actions = $perms
$subs = '/subscriptions/{{ azureSubscriptionId }}'
$role.AssignableScopes = $subs
New-AzRoleDefinition -Role $role
Get-AzRoleDefinition "Zen Master {{ azureSubscriptionId }}"
</pre
                                        >
                                        <pre *ngIf="!newAccount">
Get-AzureRmSubscription -SubscriptionId "{{ azureSubscriptionId }}" | Set-AzureRmContext
Register-AzResourceProvider -ProviderNamespace Microsoft.Compute
Register-AzResourceProvider -ProviderNamespace Microsoft.Authorization
Register-AzResourceProvider -ProviderNamespace Microsoft.Network
Register-AzResourceProvider -ProviderNamespace Microsoft.ResourceHealth
Register-AzResourceProvider -ProviderNamespace Microsoft.Resources
Register-AzResourceProvider -ProviderNamespace Microsoft.Storage
$role = Get-AzRoleDefinition "Zen Master {{ azureSubscriptionId }}"
$role.Name = 'Zen Master {{ azureSubscriptionId }}'
$role.Description = "Lets Zen Master manage virtual machines, but not access to them, and not the virtual network or storage account they're connected to."
$role.IsCustom = $true
$perms = 'Microsoft.Compute/availabilitySets/*','Microsoft.Compute/locations/*','Microsoft.Compute/virtualMachines/*'
$perms += 'Microsoft.Compute/disks/delete'
$perms += 'Microsoft.Compute/galleries/images/read'
$perms += 'Microsoft.Compute/galleries/images/versions/read'
$perms += 'Microsoft.Authorization/*/read'
$perms += 'Microsoft.Network/applicationGateways/backendAddressPools/join/action'
$perms += 'Microsoft.Network/loadBalancers/backendAddressPools/join/action'
$perms += 'Microsoft.Network/loadBalancers/inboundNatPools/join/action'
$perms += 'Microsoft.Network/loadBalancers/inboundNatRules/join/action'
$perms += 'Microsoft.Network/loadBalancers/probes/join/action'
$perms += 'Microsoft.Network/loadBalancers/read'
$perms += 'Microsoft.Network/locations/*'
$perms += 'Microsoft.Network/networkInterfaces/*'
$perms += 'Microsoft.Network/networkSecurityGroups/join/action'
$perms += 'Microsoft.Network/networkSecurityGroups/read'
$perms += 'Microsoft.Network/publicIPAddresses/*'
$perms += 'Microsoft.Network/virtualNetworks/read'
$perms += 'Microsoft.Network/virtualNetworks/subnets/join/action'
$perms += 'Microsoft.ResourceHealth/availabilityStatuses/read'
$perms += 'Microsoft.Resources/deployments/*'
$perms += 'Microsoft.Resources/subscriptions/resourceGroups/*'
$perms += 'Microsoft.Storage/storageAccounts/listKeys/action'
$perms += 'Microsoft.Storage/storageAccounts/read'
$perms += 'Microsoft.Network/publicIPPrefixes/read'
$perms += 'Microsoft.Network/publicIPPrefixes/join/action'
$role.Actions = $perms
$subs = '/subscriptions/{{ azureSubscriptionId }}'
$role.AssignableScopes = $subs
Set-AzRoleDefinition -Role $role
Get-AzRoleDefinition "Zen Master {{ azureSubscriptionId }}"
</pre
                                        >
                                    </li>
                                    <li>
                                        {{ "AZURE_DOCS.THREE_0" | translate }}<br />
                                        {{ "AZURE_DOCS.THREE_A" | translate
                                        }}<button
                                            class="btn btn-sm btn-link"
                                            (click)="
                                                copyString(
                                                    'Get-AzureRmMarketplaceTerms -Publisher nvidia -Product nvidia-gpu-optimized-vmi-a10 -Name nvidia_base_a10_vmi_22_08'
                                                )
                                            "
                                            type="button"
                                            title="{{ 'COPY' | translate }}"
                                        >
                                            <fa-icon icon="clipboard" size="md"></fa-icon>
                                        </button>
                                        <pre>
Get-AzureRmMarketplaceTerms -Publisher nvidia -Product nvidia-gpu-optimized-vmi-a10 -Name nvidia_base_a10_vmi_22_08</pre
                                        >
                                        {{ "AZURE_DOCS.THREE_B" | translate
                                        }}<button class="btn btn-sm btn-link" (click)="copyPre2()" type="button" title="{{ 'COPY' | translate }}">
                                            <fa-icon icon="clipboard" size="md"></fa-icon>
                                        </button>
                                        <pre>
Get-AzureRmSubscription -SubscriptionId "{{ azureSubscriptionId }}" | Set-AzureRmContext
Get-AzureRmMarketplaceTerms -Publisher nvidia -Product nvidia-gpu-optimized-vmi-a10 -Name nvidia_base_a10_vmi_22_08 | Set-AzureRmMarketplaceTerms -Accept</pre
                                        >
                                    </li>
                                    <li>
                                        {{ "AZURE_DOCS.FOUR_PRE" | translate }}
                                        <a
                                            target="_blank"
                                            href="https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-create-service-principal-portal"
                                            >this article</a
                                        >
                                        {{ "AZURE_DOCS.FOUR_POST" | translate }}
                                        <ul>
                                            <li>
                                                {{ "AZURE_DOCS.FOUR_A_PRE" | translate }}{{ azureSubscriptionId }}{{ "AZURE_DOCS.FOUR_A_POST" | translate }}
                                            </li>
                                            <li>{{ "AZURE_DOCS.FOUR_B" | translate }} https://{{ userAccount?.dns_prefix }}.zen.zixi.com/azure</li>
                                            <li>
                                                {{ "AZURE_DOCS.FOUR_C" | translate }}
                                                <ul>
                                                    <li translate>AZURE_DOCS.FOUR_C_1</li>
                                                    <li translate>AZURE_DOCS.FOUR_C_2</li>
                                                    <li translate>AZURE_DOCS.FOUR_C_3</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                    <li>
                                        {{ "AZURE_DOCS.FIVE_A" | translate }}<br />
                                        {{ "AZURE_DOCS.FIVE_B" | translate }}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
