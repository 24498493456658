import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { AzureAccountsService } from "../azure-accounts.service";
import { AzureAccount } from "../../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class AzureAccountListResolverService implements Resolve<AzureAccount[]> {
    constructor(private tps: AzureAccountsService) {}

    resolve(): Observable<AzureAccount[]> | Observable<never> {
        return new Observable((observe: Subscriber<AzureAccount[]>) => {
            this.tps.refreshAzureAccounts().subscribe((azureAccounts: AzureAccount[]) => {
                observe.next(azureAccounts);
                observe.complete();
            });
        });
    }
}
