import { Component, OnInit, OnDestroy } from "@angular/core";
import { User } from "src/app/models/shared";
import { Subscription } from "rxjs";
import { UsersService } from "../../account-management/users/users.service";
import { ClipboardService } from "ngx-clipboard";

@Component({
    selector: "app-azure-accounts",
    templateUrl: "./azure-accounts.component.html"
})
export class AzureAccountsComponent implements OnInit, OnDestroy {
    newAccount = true;
    userAccount: User;

    azureSubscriptionId: string;

    private userAccountSubscription: Subscription;

    constructor(private us: UsersService, private cbs: ClipboardService) {}

    ngOnInit() {
        this.userAccountSubscription = this.us.user.subscribe(user => {
            this.userAccount = user;
        });
    }

    ngOnDestroy() {
        if (this.userAccountSubscription) this.userAccountSubscription.unsubscribe();
    }

    copyString(pw: string) {
        this.cbs.copy(pw);
    }

    copyPre2() {
        const pre2 =
            'Get-AzureRmSubscription -SubscriptionId "' +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            '" | Set-AzureRmContext \n Get-AzureRmMarketplaceTerms -Publisher nvidia -Product nvidia-gpu-optimized-vmi-a10 -Name nvidia_base_a10_vmi_22_08 | Set-AzureRmMarketplaceTerms -Accept';
        this.cbs.copy(pre2);
    }

    copyNewAccount() {
        const newAccount =
            'Get-AzureRmSubscription -SubscriptionId "' +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            "\" | Set-AzureRmContext \n Register-AzResourceProvider -ProviderNamespace Microsoft.Compute \n Register-AzResourceProvider -ProviderNamespace Microsoft.Authorization \n Register-AzResourceProvider -ProviderNamespace Microsoft.Network \n Register-AzResourceProvider -ProviderNamespace Microsoft.ResourceHealth \n Register-AzResourceProvider -ProviderNamespace Microsoft.Resources \n Register-AzResourceProvider -ProviderNamespace Microsoft.Storage \n $role = [Microsoft.Azure.Commands.Resources.Models.Authorization.PSRoleDefinition]::new() \n $role.Name = 'Zen Master " +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            "' \n $role.Description = \"Lets Zen Master manage virtual machines, but not access to them, and not the virtual network or storage account they're connected to.\" \n $role.IsCustom = $true \n $perms = 'Microsoft.Compute/availabilitySets/*','Microsoft.Compute/locations/*','Microsoft.Compute/virtualMachines/*' \n $perms += 'Microsoft.Compute/disks/delete' \n $perms += 'Microsoft.Compute/galleries/images/read' \n $perms += 'Microsoft.Compute/galleries/images/versions/read' \n $perms += 'Microsoft.Authorization/*/read' \n $perms += 'Microsoft.Network/applicationGateways/backendAddressPools/join/action' \n $perms += 'Microsoft.Network/loadBalancers/backendAddressPools/join/action' \n $perms += 'Microsoft.Network/loadBalancers/inboundNatPools/join/action' \n $perms += 'Microsoft.Network/loadBalancers/inboundNatRules/join/action' \n $perms += 'Microsoft.Network/loadBalancers/probes/join/action' \n $perms += 'Microsoft.Network/loadBalancers/read' \n $perms += 'Microsoft.Network/locations/*' \n $perms += 'Microsoft.Network/networkInterfaces/*' \n $perms += 'Microsoft.Network/networkSecurityGroups/join/action' \n $perms += 'Microsoft.Network/networkSecurityGroups/read' \n $perms += 'Microsoft.Network/publicIPAddresses/*' \n $perms += 'Microsoft.Network/virtualNetworks/read' \n $perms += 'Microsoft.Network/virtualNetworks/subnets/join/action' \n $perms += 'Microsoft.ResourceHealth/availabilityStatuses/read' \n $perms += 'Microsoft.Resources/deployments/*' \n $perms += 'Microsoft.Resources/subscriptions/resourceGroups/*' \n $perms += 'Microsoft.Storage/storageAccounts/listKeys/action' \n $perms += 'Microsoft.Storage/storageAccounts/read' \n $perms += 'Microsoft.Network/publicIPPrefixes/read' \n $perms += 'Microsoft.Network/publicIPPrefixes/join/action' \n $role.Actions = $perms \n $subs = '/subscriptions/" +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            "' \n $role.AssignableScopes = $subs \n New-AzRoleDefinition -Role $role \n Get-AzRoleDefinition \"Zen Master " +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            '"';
        this.cbs.copy(newAccount);
    }

    copyNotNewAccount() {
        const notNewAccount =
            'Get-AzureRmSubscription -SubscriptionId "' +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            '" | Set-AzureRmContext \n Register-AzResourceProvider -ProviderNamespace Microsoft.Compute \n Register-AzResourceProvider -ProviderNamespace Microsoft.Authorization \n Register-AzResourceProvider -ProviderNamespace Microsoft.Network \n Register-AzResourceProvider -ProviderNamespace Microsoft.ResourceHealth \n Register-AzResourceProvider -ProviderNamespace Microsoft.Resources \n Register-AzResourceProvider -ProviderNamespace Microsoft.Storage \n $role = Get-AzRoleDefinition "Zen Master ' +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            "\" \n $role.Name = 'Zen Master " +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            "' \n $role.Description = \"Lets Zen Master manage virtual machines, but not access to them, and not the virtual network or storage account they're connected to.\" \n $role.IsCustom = $true \n $perms = 'Microsoft.Compute/availabilitySets/*','Microsoft.Compute/locations/*','Microsoft.Compute/virtualMachines/*' \n $perms += 'Microsoft.Compute/disks/delete' \n $perms += 'Microsoft.Compute/galleries/images/read' \n $perms += 'Microsoft.Compute/galleries/images/versions/read' \n $perms += 'Microsoft.Authorization/*/read' \n $perms += 'Microsoft.Network/applicationGateways/backendAddressPools/join/action' \n $perms += 'Microsoft.Network/loadBalancers/backendAddressPools/join/action' \n $perms += 'Microsoft.Network/loadBalancers/inboundNatPools/join/action' \n $perms += 'Microsoft.Network/loadBalancers/inboundNatRules/join/action' \n $perms += 'Microsoft.Network/loadBalancers/probes/join/action' \n $perms += 'Microsoft.Network/loadBalancers/read' \n $perms += 'Microsoft.Network/locations/*' \n $perms += 'Microsoft.Network/networkInterfaces/*' \n $perms += 'Microsoft.Network/networkSecurityGroups/join/action' \n $perms += 'Microsoft.Network/networkSecurityGroups/read' \n $perms += 'Microsoft.Network/publicIPAddresses/*' \n $perms += 'Microsoft.Network/virtualNetworks/read' \n $perms += 'Microsoft.Network/virtualNetworks/subnets/join/action' \n $perms += 'Microsoft.ResourceHealth/availabilityStatuses/read' \n $perms += 'Microsoft.Resources/deployments/*' \n $perms += 'Microsoft.Resources/subscriptions/resourceGroups/*' \n $perms += 'Microsoft.Storage/storageAccounts/listKeys/action' \n $perms += 'Microsoft.Storage/storageAccounts/read' \n $perms += 'Microsoft.Network/publicIPPrefixes/read' \n $perms += 'Microsoft.Network/publicIPPrefixes/join/action' \n $role.Actions = $perms \n $subs = '/subscriptions/" +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            "' \n $role.AssignableScopes = $subs \n New-AzRoleDefinition -Role $role \n Get-AzRoleDefinition \"Zen Master " +
            (this.azureSubscriptionId ? this.azureSubscriptionId : "") +
            '"';
        this.cbs.copy(notNewAccount);
    }
}
