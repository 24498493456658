import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Components
import { AzureAccountsComponent } from "./azure-accounts.component";
import { AzureAccountListComponent } from "./azure-account-list/azure-account-list.component";
import { AzureAccountFormComponent } from "./azure-account-form/azure-account-form.component";

// Resolver
import { AzureAccountListResolverService } from "./azure-account-list/azure-account-list-resolver.service";

const azureAccountRoutes: Routes = [
    {
        path: Constants.urls.configuration.azure + "/new",
        component: AzureAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.azure + "/:id/:action",
        component: AzureAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.azure,
        component: AzureAccountsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.AZURE_ACCOUNTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/GwCGKg"
                }
            ]
        },
        children: [
            {
                path: "",
                component: AzureAccountListComponent,
                resolve: {
                    azureAccount: AzureAccountListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(azureAccountRoutes)],
    exports: [RouterModule]
})
export class AzureAccountsRouting {}
