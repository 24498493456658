<content-loading-animation *ngIf="loading"></content-loading-animation>

<form id="form" #form="ngForm" (ngSubmit)="form.valid && onSubmit()" *ngIf="!loading">
    <div class="title-bar">
        <div class="row justify-content-center">
            <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                <div class="title">
                    <h1 *ngIf="!isEdit">{{ "ADD" | translate }} {{ "NEW" | translate }} {{ "AZURE" | translate }} {{ "ACCOUNT" | translate }}</h1>
                    <h1 *ngIf="isEdit">{{ "EDIT" | translate }} {{ "AZURE" | translate }} {{ "ACCOUNT" | translate }}</h1>
                </div>
            </div>
        </div>
    </div>
    <div class="content-area">
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div [class]="constants.FORM_COL_CLASS_BREAKPOINTS">
                    <div class="form-group">
                        <label for="name" [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            >{{ "NAME" | translate }}<fa-icon icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="{{ 'NAME' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && name.errors }"
                            [(ngModel)]="azureAccount.name"
                            required
                            minlength="{{ minLength }}"
                            duplicateName="{{ azureAccountNames }}"
                            pattern="{{ constants.validators.name }}"
                            #name="ngModel"
                        />
                        <div *ngIf="name.invalid" class="invalid-feedback">
                            <div *ngIf="name.errors.required">{{ "NAME" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                            <div *ngIf="name.errors.minlength">
                                {{ "NAME" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }} {{ "CHARACTERS_LONG" | translate }}
                            </div>
                            <div *ngIf="name.errors.duplicateName">{{ "NAME" | translate }} {{ "MUST_BE_UNIQUE" | translate }}</div>
                            <div *ngIf="name.errors.pattern">
                                {{ "NAME" | translate }} {{ "CAN_NOT_CONTAIN_ANY_OF_THE_FOLLOWING_CHARACTERS:" | translate }} ' " `
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="subscriptionId" [ngClass]="{ 'is-invalid': form.submitted && subscriptionId.errors }"
                            >{{ "SUBSCRIPTION_ID" | translate }}<fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="subscriptionId"
                            name="subscriptionId"
                            placeholder="{{ 'SUBSCRIPTION_ID' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && subscriptionId.errors }"
                            [(ngModel)]="azureAccount.subscription_id"
                            [required]="!isEdit"
                            minlength="{{ minLength }}"
                            #subscriptionId="ngModel"
                            [disabled]="isEdit"
                        />
                        <div *ngIf="subscriptionId.invalid" class="invalid-feedback">
                            <div *ngIf="subscriptionId.errors.required">{{ "SUBSCRIPTION_ID" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                            <div *ngIf="subscriptionId.errors.minlength">
                                {{ "SUBSCRIPTION_ID" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }}
                                {{ "CHARACTERS_LONG" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="directoryId" [ngClass]="{ 'is-invalid': form.submitted && directoryId.errors }"
                            >{{ "AZURE_DIRECTORY_ID" | translate }}<fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="directoryId"
                            name="directoryId"
                            placeholder="{{ 'AZURE_DIRECTORY_ID' | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && directoryId.errors }"
                            [(ngModel)]="azureAccount.domain"
                            [required]="!isEdit"
                            minlength="{{ minLength }}"
                            #directoryId="ngModel"
                            [disabled]="isEdit"
                        />
                        <div *ngIf="directoryId.invalid" class="invalid-feedback">
                            <div *ngIf="directoryId.errors.required">{{ "AZURE_DIRECTORY_ID" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                            <div *ngIf="directoryId.errors.minlength">
                                {{ "AZURE_DIRECTORY_ID" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }}
                                {{ "CHARACTERS_LONG" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="appId" [ngClass]="{ 'is-invalid': form.submitted && appId.errors }"
                            >{{ "APPLICATION_ID" | translate }}<fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="appId"
                            name="appId"
                            placeholder="{{ (isEdit ? 'BLANK_SECRET_EDIT' : 'APPLICATION_ID') | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && appId.errors }"
                            [(ngModel)]="azureAccount.client_id"
                            [required]="!isEdit"
                            minlength="{{ minLength }}"
                            #appId="ngModel"
                        />
                        <div *ngIf="appId.invalid" class="invalid-feedback">
                            <div *ngIf="appId.errors.required">{{ "APPLICATION_ID" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                            <div *ngIf="appId.errors.minlength">
                                {{ "APPLICATION_ID" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }}
                                {{ "CHARACTERS_LONG" | translate }}
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="authKey" [ngClass]="{ 'is-invalid': form.submitted && authKey.errors }"
                            >{{ "AUTHENTICATION_KEY" | translate }}<fa-icon *ngIf="!isEdit" icon="asterisk" size="xs"></fa-icon
                        ></label>
                        <input
                            type="text"
                            id="authKey"
                            name="authKey"
                            placeholder="{{ (isEdit ? 'BLANK_SECRET_EDIT' : 'AUTHENTICATION_KEY') | translate }}"
                            class="form-control"
                            [ngClass]="{ 'is-invalid': form.submitted && authKey.errors }"
                            [(ngModel)]="azureAccount.secret"
                            [required]="!isEdit"
                            minlength="{{ minLength }}"
                            #authKey="ngModel"
                        />
                        <div *ngIf="authKey.invalid" class="invalid-feedback">
                            <div *ngIf="authKey.errors.required">{{ "AUTHENTICATION_KEY" | translate }} {{ "IS_REQUIRED" | translate }}</div>
                            <div *ngIf="authKey.errors.minlength">
                                {{ "AUTHENTICATION_KEY" | translate }} {{ "MUST_BE_AT_LEAST" | translate }} {{ minLength }}
                                {{ "CHARACTERS_LONG" | translate }}
                            </div>
                        </div>
                    </div>
                    <app-error [marginBottom]="true" [marginTop]="false"></app-error>
                    <hr class="mt-0" />
                    <div class="text-end">
                        <button type="button" class="btn btn-outline-secondary me-2" (click)="cancel()" [disabled]="saving">
                            <fa-icon icon="times"></fa-icon>
                            {{ "CANCEL" | translate }}
                        </button>
                        <button
                            zmid="form-submit"
                            type="submit"
                            class="btn btn-primary"
                            [disabled]="saving"
                            [ngClass]="{ 'btn-danger': form.submitted && form.invalid }"
                        >
                            <fa-icon icon="check" size="sm"></fa-icon>
                            {{ "SAVE" | translate }}<span *ngIf="saving" class="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
