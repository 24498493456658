import { Injectable } from "@angular/core";
import { Observable, ReplaySubject, from } from "rxjs";
import { map, share } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import { AzureAccount } from "../../../models/shared";
import { AuthService } from "src/app/services/auth.service";
import { ZenApiService } from "../../../services/zen-rpc-service";
import { InferredRequests } from "@zixi/zen-rpc";

@Injectable({
    providedIn: "root"
})
export class AzureAccountsService {
    azureAccounts: Observable<AzureAccount[]>;
    private azureAccounts$: ReplaySubject<AzureAccount[]>;
    private dataStore: {
        azureAccounts: AzureAccount[];
    };

    constructor(private authService: AuthService, private zenApi: ZenApiService, private translate: TranslateService) {
        this.reset();

        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            azureAccounts: []
        };
        this.azureAccounts$ = new ReplaySubject(1) as ReplaySubject<AzureAccount[]>;
        this.azureAccounts = this.azureAccounts$.asObservable();
    }

    private prepAzureAccount(azureAccount: AzureAccount) {
        return azureAccount;
    }

    private updateStore(azureAccount: AzureAccount, merge: boolean): void {
        this.prepAzureAccount(azureAccount);

        const currentIndex = this.dataStore.azureAccounts.findIndex(g => g.id === azureAccount.id);
        if (currentIndex === -1) {
            this.dataStore.azureAccounts.push(azureAccount);
            return;
        } else if (merge) {
            const current = this.dataStore.azureAccounts[currentIndex];

            Object.assign(current, azureAccount);

            const relationships = [];
            relationships.forEach(overwrite => {
                if (current[overwrite.id] == null) current[overwrite.obj] = null;
            });
        } else {
            this.dataStore.azureAccounts[currentIndex] = azureAccount;
        }
    }

    refreshAzureAccounts(): Observable<AzureAccount[]> {
        const azureAccounts$ = from(this.zenApi.client.azureAccount.getList()).pipe(share());

        azureAccounts$.subscribe({
            next: data => {
                const azureAccounts = data.body.result;

                this.dataStore.azureAccounts.forEach((existing, existingIndex) => {
                    const newIndex = azureAccounts.findIndex(tp => tp.id === existing.id);
                    if (newIndex === -1) this.dataStore.azureAccounts.splice(existingIndex, 1);
                });

                azureAccounts.forEach(tp => this.updateStore(tp, true));

                this.azureAccounts$.next(Object.assign({}, this.dataStore).azureAccounts);
            },
            // eslint-disable-next-line no-console
            error: error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_TRANSCODING_PROFILES"), error)
        });

        return azureAccounts$.pipe(map(r => r.body.result));
    }

    async addAzureAccount(params: InferredRequests["azureAccount"]["create"]) {
        try {
            const result = await this.zenApi.client.azureAccount.create(params);
            if (result.status !== 200) return false;

            const azureAccount = result.body.result;
            this.updateStore(azureAccount, false);
            this.azureAccounts$.next(Object.assign({}, this.dataStore).azureAccounts);
            return azureAccount;
        } catch (error) {
            return false;
        }
    }

    async deleteAzureAccount(id: number): Promise<boolean> {
        try {
            const result = await this.zenApi.client.azureAccount.destroy({ params: { id } });

            if (result.status !== 200) return false;

            const deletedId: number = result.body.result;

            const index = this.dataStore.azureAccounts.findIndex(a => a.id === deletedId);
            if (index !== -1) this.dataStore.azureAccounts.splice(index, 1);

            this.azureAccounts$.next(Object.assign({}, this.dataStore).azureAccounts);

            return true;
        } catch (error) {
            return false;
        }
    }

    async updateAzureAccount(params: InferredRequests["azureAccount"]["update"]) {
        try {
            const result = await this.zenApi.client.azureAccount.update(params);

            if (result.status !== 200) return false;
            const updatedAzureAccount = result.body.result;
            this.updateStore(updatedAzureAccount, true);

            this.azureAccounts$.next(Object.assign({}, this.dataStore).azureAccounts);

            return updatedAzureAccount;
        } catch (error) {
            return false;
        }
    }
}
